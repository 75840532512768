import { Bars3Icon, TrashIcon } from "@heroicons/react/24/outline";
import { genInternalId } from "@sp-crm/core";
import { produce } from "immer";
import React, { useCallback } from "react";
import {
    calculateNewOrder,
    useReorderableListDrag,
    useReorderableListDrop,
} from "util/dnd";
import { Input } from "./input";
import { SecondaryButton } from "./secondary-button";

interface StringListItemProps {
    value: string;
    index: number;
    order: number;
    id: string;
    listId: string;
    onChange: (value: string) => void;
    onBlur: () => void;
    onDelete: () => void;
    onEnter: () => void;
    commitOrder: () => void;
    reorder: (draggingIndex: number, targetIndex: number) => void;
}

const StringListItem: React.FC<StringListItemProps> = props => {
    const {
        value,
        index,
        id,
        listId,
        onChange,
        onBlur,
        onDelete,
        onEnter,
        commitOrder,
        reorder,
    } = props;

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(e.target.value);
        },
        [onChange],
    );

    const handleKeyPress = useCallback(
        (e: React.KeyboardEvent) => {
            if (e.key === "Enter") {
                e.preventDefault();
                onEnter();
            }
        },
        [onEnter],
    );

    const ref = React.useRef<HTMLLIElement>(null);
    const type = `reorderable-string-list-${listId}`;

    const [{ isDragging }, drag, preview] = useReorderableListDrag({
        type,
        index,
        commitOrder,
        id,
    });

    // eslint-disable-next-line no-empty-pattern
    const [{}, drop] = useReorderableListDrop({
        ref,
        index,
        reorder,
        type,
    });

    drop(ref);

    return (
        <li ref={ref}>
            <div
                ref={preview}
                className={`py-1 flex space-x-2 ${isDragging ? "opacity-0" : ""}`}>
                <div
                    className="cursor-move px-1 py-3"
                    ref={drag}
                    tabIndex={-1}
                    aria-label="Drag handle">
                    <Bars3Icon className="w-4 h-4 text-gray-500" />
                </div>
                <Input
                    placeholder="Value"
                    value={value}
                    onChange={handleChange}
                    onBlur={onBlur}
                    onKeyPress={handleKeyPress}
                    autoFocus={value === ""}
                />
                <button
                    onClick={onDelete}
                    type="button"
                    className="p-2 hover:bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    aria-label="Delete item">
                    <TrashIcon className="w-4 h-4" />
                </button>
            </div>
        </li>
    );
};

interface OrderedString {
    id: string;
    value: string;
    order: number;
    isDirty?: boolean;
}

interface ReorderableStringListProps {
    label: string;
    initial: string[];
    onCommit: (values: string[]) => void;
    addButtonLabel?: string;
}

export const ReorderableStringList: React.FC<ReorderableStringListProps> = props => {
    const { label, initial, onCommit, addButtonLabel } = props;

    // Create a stable ID for this list instance
    const listId = React.useMemo(() => `${label}-${genInternalId()}`, [label]);

    const [items, setItems] = React.useState<OrderedString[]>(() => {
        const orderedItems = initial.map((value, index) => ({
            id: genInternalId(),
            value,
            order: index,
            isDirty: false,
        }));
        orderedItems.sort((a, b) => a.order - b.order);
        return orderedItems;
    });

    React.useEffect(() => {
        const orderedItems = initial.map((value, index) => ({
            id: genInternalId(),
            value,
            order: index,
            isDirty: false,
        }));
        orderedItems.sort((a, b) => a.order - b.order);
        setItems(orderedItems);
    }, [initial]);

    const handleChange = useCallback(
        (id: string, value: string) => {
            const newItems = produce(items, draft => {
                const item = draft.find(i => i.id === id);
                if (item) {
                    item.value = value;
                    item.isDirty = true;
                }
            });
            setItems(newItems);
        },
        [items],
    );

    const handleBlur = useCallback(
        (id: string) => {
            const item = items.find(i => i.id === id);
            if (item?.isDirty) {
                const newItems = produce(items, draft => {
                    const item = draft.find(i => i.id === id);
                    if (item) {
                        item.isDirty = false;
                    }
                });
                setItems(newItems);
                onCommit(items.map(i => i.value));
            }
        },
        [items, onCommit],
    );

    const handleDelete = useCallback(
        (id: string) => {
            const newItems = produce(items, draft => {
                const index = draft.findIndex(i => i.id === id);
                if (index !== -1) {
                    draft.splice(index, 1);
                    // Update orders after deletion
                    draft.forEach((item, idx) => {
                        item.order = idx;
                    });
                }
            });
            setItems(newItems);
            onCommit(newItems.map(i => i.value));
        },
        [items, onCommit],
    );

    const handleAdd = useCallback(
        (e?: React.MouseEvent<HTMLButtonElement>) => {
            e && e.preventDefault && e.preventDefault();
            const newItems = produce(items, draft => {
                const order = draft.length;
                draft.push({
                    id: genInternalId(),
                    value: "",
                    order,
                    isDirty: false,
                });
            });
            setItems(newItems);
            onCommit(newItems.map(i => i.value));
        },
        [items, onCommit],
    );

    const reorder = useCallback(
        (draggingIndex: number, targetIndex: number) => {
            const newItems = produce(items, draft => {
                const newOrder = calculateNewOrder(draft, draggingIndex, targetIndex);
                draft[draggingIndex].order = newOrder;
                draft.sort((a, b) => a.order - b.order);
            });
            setItems(newItems);
        },
        [items],
    );

    const commitOrder = useCallback(() => {
        const newItems = produce(items, draft => {
            draft.forEach((item, index) => {
                item.order = index;
            });
        });
        setItems(newItems);
        onCommit(newItems.map(i => i.value));
    }, [items, onCommit]);

    return (
        <div className="mt-4">
            <div className="mb-2 font-medium">{label}</div>
            <ul className="mb-2">
                {items.map((item, index) => (
                    <StringListItem
                        key={item.id}
                        id={item.id}
                        listId={listId}
                        value={item.value}
                        order={item.order}
                        index={index}
                        onChange={value => handleChange(item.id, value)}
                        onBlur={() => handleBlur(item.id)}
                        onDelete={() => handleDelete(item.id)}
                        onEnter={handleAdd}
                        reorder={reorder}
                        commitOrder={commitOrder}
                    />
                ))}
            </ul>
            <SecondaryButton onClick={handleAdd}>
                {addButtonLabel ?? `Add ${label.toLowerCase()}`}
            </SecondaryButton>
        </div>
    );
};
