import {
    ChevronDownIcon,
    ChevronRightIcon,
    FolderIcon,
    FolderOpenIcon,
} from "@heroicons/react/24/outline";
import { GetSavedQueriesQuery } from "generated/graphql";
import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { activeLinkStyle, linkStyle } from "./custom-reports-sidebar";

export type SavedQuery = GetSavedQueriesQuery["getSavedQueries"]["savedQueries"][0];

interface FolderGroupProps {
    name: string;
    queries: SavedQuery[];
    currentReportId: string | undefined;
    searchText: string;
    isOpen: boolean;
    onToggle: () => void;
}

export const FolderGroup: React.FC<FolderGroupProps> = ({
    name,
    queries,
    currentReportId,
    searchText,
    isOpen,
    onToggle,
}) => {
    const matchesSearch = useMemo(() => {
        // If folder name matches the search
        if (name.toLowerCase().includes(searchText.toLowerCase())) {
            return true;
        }

        // If any query in the folder matches the search
        return queries.some(query =>
            query.name?.toLowerCase().includes(searchText.toLowerCase()),
        );
    }, [queries, searchText, name]);

    // If search is active and nothing matches, don't show
    if (searchText && !matchesSearch) {
        return null;
    }

    const filteredQueries = searchText
        ? queries.filter(q => q.name?.toLowerCase().includes(searchText.toLowerCase()))
        : queries;

    return (
        <li className="mb-2">
            <div
                className="flex items-center space-x-1 cursor-pointer hover:bg-gray-100 p-1 rounded"
                onClick={onToggle}>
                <div className="w-4">
                    {isOpen ? (
                        <ChevronDownIcon className="h-4 w-4 text-gray-500" />
                    ) : (
                        <ChevronRightIcon className="h-4 w-4 text-gray-500" />
                    )}
                </div>
                {isOpen ? (
                    <FolderOpenIcon className="h-4 w-4 text-gray-600" />
                ) : (
                    <FolderIcon className="h-4 w-4 text-gray-600" />
                )}
                <span className="text-sm font-medium text-gray-700">{name}</span>
                <span className="text-xs text-gray-500">({filteredQueries.length})</span>
            </div>

            {isOpen && (
                <ul className="pl-7 pt-1 space-y-1">
                    {filteredQueries.map(query => (
                        <li key={query.id}>
                            <Link
                                to={`/reports/show/custom/${query.id}`}
                                className={`flex items-center space-x-1 ${
                                    query.id === currentReportId
                                        ? activeLinkStyle
                                        : linkStyle
                                }`}>
                                <span>{query.name || "(Untitled)"}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </li>
    );
};

interface QueriesWithFoldersProps {
    title: string;
    queries: SavedQuery[];
    currentReportId: string | undefined;
    searchText: string;
}

export const QueriesWithFolders: React.FC<QueriesWithFoldersProps> = ({
    title,
    queries,
    currentReportId,
    searchText,
}) => {
    // Group queries by folder
    const { folderGroups, unfolderizedQueries } = useMemo(() => {
        const groups: Record<string, SavedQuery[]> = {};
        const noFolder: SavedQuery[] = [];

        queries.forEach(query => {
            if (query.folderName) {
                if (!groups[query.folderName]) {
                    groups[query.folderName] = [];
                }
                groups[query.folderName].push(query);
            } else {
                noFolder.push(query);
            }
        });

        return {
            folderGroups: Object.entries(groups).sort(([a], [b]) => a.localeCompare(b)),
            unfolderizedQueries: noFolder,
        };
    }, [queries]);

    // Track open folders
    const [openFolders, setOpenFolders] = useState<Record<string, boolean>>({});

    // Initialize with current report's folder open
    useMemo(() => {
        if (currentReportId) {
            const currentQuery = queries.find(q => q.id === currentReportId);
            if (currentQuery?.folderName) {
                setOpenFolders(prev => ({
                    ...prev,
                    [currentQuery.folderName]: true,
                }));
            }
        }
    }, [currentReportId, queries]);

    const toggleFolder = useCallback((folderName: string) => {
        setOpenFolders(prev => ({
            ...prev,
            [folderName]: !prev[folderName],
        }));
    }, []);

    // Filter unfolderzed queries for search
    const filteredUnfolderizedQueries = useMemo(() => {
        if (!searchText) return unfolderizedQueries;
        return unfolderizedQueries.filter(query =>
            query.name?.toLowerCase().includes(searchText.toLowerCase()),
        );
    }, [unfolderizedQueries, searchText]);

    // If nothing to show due to search, return null
    if (
        searchText &&
        folderGroups.length === 0 &&
        filteredUnfolderizedQueries.length === 0
    ) {
        return null;
    }

    // If there are no queries at all, return null
    if (queries.length === 0) {
        return null;
    }

    return (
        <>
            <li className="pt-6 uppercase text-gray-600 tracking-wider text-xs mb-1">
                {title}
            </li>

            {/* Folders first */}
            {folderGroups.map(([folderName, folderQueries]) => (
                <FolderGroup
                    key={folderName}
                    name={folderName}
                    queries={folderQueries}
                    currentReportId={currentReportId}
                    searchText={searchText}
                    isOpen={!!openFolders[folderName]}
                    onToggle={() => toggleFolder(folderName)}
                />
            ))}

            {/* Then queries without folders */}
            {filteredUnfolderizedQueries.map(query => (
                <li key={query.id}>
                    <Link
                        to={`/reports/show/custom/${query.id}`}
                        className={`flex items-center space-x-1 ${
                            query.id === currentReportId ? activeLinkStyle : linkStyle
                        }`}>
                        <span>{query.name || "(Untitled)"}</span>
                    </Link>
                </li>
            ))}
        </>
    );
};
