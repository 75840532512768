import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { SearchInput } from "components/ui/search-input";
import { useGetSavedQueriesQuery } from "generated/graphql";
import React, { useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { CustomReportsRouteParams } from "./custom-reports-helpers";
import { QueriesWithFolders, SavedQuery } from "./folder-components";

export const linkStyle =
    "text-base block -mx-2 px-2 py-2 text-brand-600 hover:bg-gray-200 hover:text-brand-700 rounded twoverride";

export const activeLinkStyle =
    "text-base block -mx-2 px-2 py-2 text-brand-500 bg-gray-50 hover:bg-gray-200 hover:text-brand-700 rounded twoverride";

// Simple component for the "New query" link
const NewQueryLink: React.FC<{ isActive: boolean }> = ({ isActive }) => (
    <div className="mt-4">
        <Link
            to="/reports/show/custom/new"
            className={isActive ? activeLinkStyle : linkStyle}>
            <span className="flex items-center space-x-1">
                <PlusCircleIcon className="h-4 w-4" />
                <div>New query</div>
            </span>
        </Link>
    </div>
);

// Component for displaying favorite queries
const FavoriteQueriesList: React.FC<{
    queries: SavedQuery[];
    currentReportId: string | undefined;
    searchText: string;
}> = ({ queries, currentReportId, searchText }) => {
    if (queries.length === 0) return null;

    const filteredQueries = searchText
        ? queries.filter(d => d.name?.toLowerCase().includes(searchText.toLowerCase()))
        : queries;

    if (filteredQueries.length === 0) return null;

    return (
        <>
            <li className="pt-6 uppercase text-gray-600 tracking-wider text-xs mb-1">
                Favorites
            </li>
            {filteredQueries.map(d => (
                <li key={d.id}>
                    <Link
                        to={`/reports/show/custom/${d.id}`}
                        className={`flex items-center space-x-1 ${
                            d.id === currentReportId ? activeLinkStyle : linkStyle
                        }`}>
                        <span>{d.name ?? "(Untitled)"}</span>
                    </Link>
                </li>
            ))}
        </>
    );
};

export const CustomReportsSidebar: React.FC = () => {
    const query = useGetSavedQueriesQuery();
    const { params } = useRouteMatch<CustomReportsRouteParams>();
    const reportId = params.reportId;
    const isNew = !reportId || reportId === "new";

    const [searchText, setSearchText] = useState("");

    return (
        <div className="md:w-36 lg:w-48 ml-4 shrink-0">
            <NewQueryLink isActive={isNew} />
            <QueryRenderer name="sidebar" query={query}>
                {data => {
                    const favoriteQueries = data.getSavedQueries.savedQueries.filter(
                        q => q.isFavorite,
                    );
                    const myQueries = data.getSavedQueries.savedQueries.filter(
                        q => !q.isShared,
                    );
                    const sharedQueries = data.getSavedQueries.savedQueries.filter(
                        q => q.isShared,
                    );

                    const hasAnyQueries =
                        myQueries.length > 0 || sharedQueries.length > 0;

                    return (
                        <ul>
                            {hasAnyQueries ? (
                                <li>
                                    <div className="mt-6">
                                        <SearchInput
                                            value={searchText}
                                            onChange={e => {
                                                setSearchText(e.target.value);
                                            }}
                                        />
                                    </div>
                                </li>
                            ) : null}

                            <FavoriteQueriesList
                                queries={favoriteQueries}
                                currentReportId={reportId}
                                searchText={searchText}
                            />

                            <QueriesWithFolders
                                title="My Queries"
                                queries={myQueries}
                                currentReportId={reportId}
                                searchText={searchText}
                            />

                            <QueriesWithFolders
                                title="Shared Queries"
                                queries={sharedQueries}
                                currentReportId={reportId}
                                searchText={searchText}
                            />
                        </ul>
                    );
                }}
            </QueryRenderer>
        </div>
    );
};
